import { createRouter, createWebHistory } from 'vue-router'

const routerHistory = createWebHistory()

const router = createRouter({
  history: routerHistory,
  routes: [
    {
      path: '/active/:activeId',
      name: '活动链接',
      component: () => import('../views/active')
    },
    {
      path: '/mengmeng',
      name: 'mengmeng',
      component: () => import('../views/mengmeng')
    },
    {
      path: '/chengzi',
      name: 'chengzi',
      component: () => import('../views/chengzi')
    },
    {
      path: '/shiguang',
      name: 'shiguang',
      component: () => import('../views/shiguang')
    },
    {
      path: '/weiguang',
      name: 'weiguang',
      component: () => import('../views/weiguang')
    },
  ]
})

export default router
