import { createApp } from 'vue'
import App from './App.vue'
import router from "./router/index.js"
import './assets/style/reset.css'
import KCoin from './components/KCoin.vue'

const app = createApp(App);

app.use(router)
app.component('KCoin', KCoin)
app.mount("#app");
