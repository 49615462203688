<template>
  <div>
    <!-- 得<span>{{getKCoin}}</span>金币 -->
    {{ config.recharge_money }}+{{ config.give_ticket }}金币
  </div>
</template>

<script>
export default {
  props: {
    config: {
      type: Object
    },
  },
  computed: {
    // getKCoin() {
    //   const { recharge_money, give_ticket } = this.config
    //   return Number(recharge_money) + Number(give_ticket)
    // }
  },
  data() {
    return {}
  },
  created() {},
  methods: {}
}
</script>

<style lang='scss' scoped>
</style>